:root {
    --white: #fff;
    --d-nav: #090C13;
    --d-bg: #1F2933;
    --d-navicon: #BAE3FF;
    --icon: #829AB1;
    --d-btn: #E6F6FF;
    --d-ser: #102A43;
}


// Here you can add other styles
.sidebar {
    --cui-sidebar-bg: var(--d-nav);
}
.bg-dark {
    background-color: var(--d-bg) !important;
}


.header {
    --cui-header-bg: var(--d-nav);
    --cui-header-color:rgb(255 255 255 / 87%);
    --cui-header-border-color: #d8dbe0;
    --cui-header-border: 1px solid #1b1e25;
}
.header-toggler {
    
    color: #d8dbe0;
}
.inp {
    
    background: none;
    color: rgb(198, 193, 193);
    
    > .form-control {
        + .form-control {
          margin-top: .5rem;
        }
}
}
.inp:focus {
    color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
    background: none;
    border-color: var(--cui-input-focus-border-color, #ffffff);
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
   
}

body {
    color: rgb(198, 193, 193);
}

.brdr-li{
    border-color: transparent;
}
.brdr-li:hover{
    border-color: transparent;
}
.title-image {
    height: 200px;
  }
  .card{
    --cui-card-bg: var(--d-nav) !important;
  }

  .wid{
    width: 70%;
  }